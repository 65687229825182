body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/* pdfheadercss */
#responsivepdfheader {
  width:95%;
  margin:auto;
  padding:30px 0px;
}

@media screen and (max-width: 600px) {
  #responsivepdfheader {
    padding: 30px 10px;
  }
  #responsivepdfheader b,
  span {
    font-size: 8px !important;
    line-height: 10px !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
  border-radius: 10px;
}

#footerScroll {
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-color: #1976d2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: transform 0.3s ease;
  padding: 0px 7px;
  flex-wrap: wrap;
  font-size: 12px;
}

/* Media query for small devices (up to 600px wide) */
@media screen and (max-width: 600px) {
  #footerScroll {
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    bottom: -3px;
    align-items: center;
  }
  #footerScroll span {
    margin-bottom: 4px;
    font-size: 0.6rem !important;
  }
  #footerScroll span:nth-last-child(2) {
    display: none;
  }
}



/* srf ducinward modal grid css */
.srf-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
}

/* Responsive design */
@media only screen and (max-width: 1200px) {
  .srf-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 992px) {
  .srf-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .srf-grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .srf-grid-container {
    grid-template-columns: 1fr;
  }
}


/* hover link css */
.custom-link {
  color: black;
  text-decoration: none;
}

.custom-link:hover {
  color: blue; 
  text-decoration: underline;
}

/* disbled field remove css */
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled,
.css-1ald77x.Mui-disabled {
  color: rgba(0, 0, 0, 0.6) !important;
}
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-1o6z5ng.Mui-disabled,
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled,
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8) !important;
}